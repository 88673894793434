import { SignIn } from "@clerk/nextjs";
import { Center } from "@mantine/core";
import * as m from "@/paraglide/messages";
import { Layout } from "@/components";
import SEO from "@/components/seo";
import { languageTag } from "@/paraglide/runtime";

export default function Page() {
  console.log("lang", languageTag());
  // const router = useRouter();

  // useEffect(() => {
  //   if (isSignedIn) {
  //     router.push("/app/patients");
  //   }
  // }, [isSignedIn]);
  return (
    <>
      <SEO
        title={m.yummy_slick_ratin()}
        description={m.ayal_barbose_ratings()}
        pageSlug="/sign-in"
      />

      <Layout displayMyPatientsButton={false} displaySignInButton={false}>
        <Center mt="xl">
          <SignIn
            routing="hash"
            forceRedirectUrl="/app/patients"
            appearance={{
              elements: {
                formButtonPrimary: {
                  backgroundColor: "#1864ab",
                  "&:hover, &:focus, &:active": {
                    backgroundColor: "#1971C2",
                  },
                },
              },
            }}
          />
        </Center>
      </Layout>
    </>
  );
}
